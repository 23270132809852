import { createSlice } from "@reduxjs/toolkit";

// load cart items from local storage if any
let localStorageGotSomething = "";

if(typeof window !== "undefined") {
    if(localStorage.getItem('orderByNoAuthReducer')) {
        localStorageGotSomething = JSON.parse(localStorage.getItem('orderByNoAuthReducer'));
    } else {
        localStorageGotSomething = "";
    }
}

export const orderByNoAuthSlice = createSlice({

    name: 'orderByNoAuth',
    initialState: localStorageGotSomething,
    reducers: {
        Order_By_No_Auth: (state , action) => {
            return action.payload;  
        }
    }
});


export const { Order_By_No_Auth } = orderByNoAuthSlice.actions;

export default orderByNoAuthSlice.reducer;